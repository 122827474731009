import React, {useContext, useEffect, useState} from "react";
import DataContext from "context/DataContext";
import {Api} from "services/api";
import {Toaster} from "react-hot-toast";
import {t} from "i18next";
import {Card, Row, Col, Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

import PermissionCheck from "components/permissioncheck/permissioncheck";
import {usePermissions} from "hooks/usePermissions";
import IAssistant from "interfaces/IAssistant";

import Documents from "./components/Documents";

import "./Assistant.css";
import Container from "react-bootstrap/Container";
import {FaArrowRightLong} from "react-icons/fa6";

const user_id = parseInt(localStorage.getItem("user_id") ?? '0');

const Assistants = () => {
    const navigate = useNavigate();
    const {checkPermissions} = usePermissions();
    const {profile} = useContext(DataContext);
    const [assistants, setAssistants] = useState<IAssistant[]>([]);
    const [filteredAssistants, setFilteredAssistants] = useState<IAssistant[]>([]);
    const [loadingAssistantList, setLoadingAssistantList] = useState(false);
    const [filters, setFilters] = useState<any>({});
    const [sections, setSections] = useState<string[]>([]);


    const getAssistantList = () => {
        if (profile.id) {
            try {
                setLoadingAssistantList(true);
                Api.get<IAssistant[]>('assistant/assistants-list').then((response) => {
                    setAssistants(response);
                    setLoadingAssistantList(false);
                }).catch((error) => {
                    console.error(error)
                })
            } catch (e) {
                console.error(e)
            }
        }
    };

    useEffect(() => {
        checkPermissions(['assistant.view_assistant', 'assistant.edit_assistant', 'chat.view_chat', 'assistant.view_document']);

        const apiUrl = localStorage.getItem('apiUrl');
        if (!apiUrl) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            window.location.href = '/edit-url';
        }
    }, []);

    useEffect(getAssistantList, [profile.id]);

    useEffect(() => {
        const filtered = assistants.filter((assistant) => {
            let result = true;

            if (typeof filters.name !== 'undefined' && filters.name.length && assistant.name.toLowerCase().indexOf(filters.name.toLowerCase()) === -1) {
                result = false;
            }

            return result;
        });
        const allSections = filtered.map(assistant => assistant.section?.name)
        const filteredSections = allSections.filter(section => section !== undefined);
        // let sec = [];
        // for( let i = 0; i < filteredSections.length; i++ ) {
        //
        // }
        // @ts-ignore
        setSections([...new Set(filteredSections)]);
        setFilteredAssistants(filtered);
    }, [filters, assistants]);

    const startChat = (assistant: IAssistant) => {
        if (localStorage.getItem('assistant_id') !== assistant.assistant_id) {
            localStorage.setItem('assistant_id', assistant.assistant_id);
            localStorage.setItem('assistant_profile_image', assistant.profile_image);
            localStorage.removeItem('thread_id');
        }
        navigate('/chat');
    }

    return (
        <PermissionCheck permission="assistant.view_assistant">
            <div className="mt-2">
                {sections.map((section ) => (
                <Card className="mb-2" key={section}>
                    <Card.Body className="d-flex flex-wrap gap-2">
                        <Card.Title className="text-center w-100 merriweather-bold" style={{fontSize:'2rem'}}>{section}</Card.Title>
                        <Container>

                        <Row>
                            {filteredAssistants.filter(assistant => assistant.section?.name === section).map((assistant) => (
                                <Col md={4} key={assistant.id}
                                     className="mb-4 d-flex">
                                    <Card
                                        className="text-center h-100 d-flex flex-column">
                                        <Card.Img src={assistant.profile_image} />
                                        <Card.Body
                                            className="d-flex flex-column">
                                            {/*<div className="d-flex justify-content-center mb-2">*/}
                                            {/*    <img src={assistant.profile_image} alt="" style={{width: '200px'}} />*/}
                                            {/*</div>*/}
                                            <Card.Title>
                                                <div className="text-center w-100 merriweather-bold">
                                                    {assistant.name}
                                                </div>
                                            </Card.Title>
                                            <Card.Text
                                                className="flex-grow-1"  dangerouslySetInnerHTML={{ __html: assistant.description.split('******')[0]}} /> {/* A szöveg kitölti a rendelkezésre álló helyet */}
                                            <div className="d-flex justify-content-between">
                                                <Documents assistant={assistant}/>
                                                <PermissionCheck permission="chat.view_chat" force={assistant.owner_id === user_id}>
                                                    <Button
                                                        variant="primary"
                                                        type="submit"
                                                        onClick={e => {
                                                            startChat(assistant)
                                                        }}
                                                    >
                                                        <div
                                                            className="d-flex justify-content-start align-items-center">
                                                            <span>{t("assistants.start")}&nbsp;</span>
                                                            <FaArrowRightLong />
                                                        </div>
                                                    </Button>
                                                </PermissionCheck>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        </Container>
                    </Card.Body>

                    {/*
                    <Table responsive="sm" hover className="align-middle">
                        <thead>
                        <tr>
                            <th>
                                {t("assistants.table.name")}
                                <FilterText filters={filters} setFilters={setFilters} field="name"
                                            fieldLabel={t("assistants.table.name")}></FilterText>
                            </th>
                            <th className="text-end">{t("assistants.table.operations")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {filteredAssistants.length === 0 &&
                            <tr>
                                <td colSpan={2} className="text-center">
                                    {loadingAssistantList &&
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    }
                                    {!loadingAssistantList &&
                                        t('assistants.noAssistants')
                                    }
                                </td>
                            </tr>
                        }
                        {filteredAssistants.map((assistant) => (
                            <tr key={assistant.id}>
                                <td>
                                    <div className="d-flex justify-content-start align-items-center">
                                        {assistant.profile_image && <div className="profile-image d-inline-flex me-2">
                                            <img src={assistant.profile_image} alt=""/></div>}
                                        <div>{assistant.name}</div>
                                    </div>
                                </td>
                                <td className="text-end">
                                    <Stack direction="horizontal" gap={2} className="justify-content-end">
                                        <PermissionCheck permission="chat.view_chat">
                                            <Button
                                                variant="dark"
                                                size="sm"
                                                type="submit"
                                                onClick={e => {
                                                    startChat(assistant)
                                                }}
                                            >
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <span>{t("assistants.start")}</span>
                                                </div>
                                            </Button>
                                        </PermissionCheck>
                                        <Documents assistant={assistant}/>
                                    </Stack>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                 */}
                </Card>
                ))}
            </div>
            <Toaster/>
        </PermissionCheck>
    );
}

export default Assistants;