import {Container, Tab, Tabs} from "react-bootstrap";
import {t} from "i18next";
import React, {useContext, useEffect, useState} from "react";
import {Toaster} from "react-hot-toast";

import Assistants from "pages/assistant/Assistants";
import StatPage from "pages/stat/StatPage";
import GroupPage from "pages/group/GroupPage";
import {usePermissions} from "hooks/usePermissions";
import FilePage from "pages/file/FilePage";
import DataContext from "../../context/DataContext";
import Alert from "react-bootstrap/Alert";
import {PiWarningBold} from "react-icons/pi";

const rights = [
    'assistant.view_assistant',
    'file.view_file',
    'group.view_group',
    ''
];

const Home = () => {
    const [activeKey, setActiveKey] = useState<string>(() => {
        const tabKey = sessionStorage.getItem("tabKey");
        if (tabKey) {
            return tabKey;
        }
        return 'audios';
    });
    const {userPermissions} = useContext(DataContext);
    const {checkPermissions} = usePermissions();
    const [showNoRightsMessage, setShowNoRightsMessage] = useState<boolean>(false);

    useEffect(() => {
        checkPermissions(rights);
        const apiUrl = localStorage.getItem('apiUrl');
        if (!apiUrl) {
            localStorage.removeItem('token');
            localStorage.removeItem('user_id');
            window.location.href = '/edit-url';
        }
    }, []);
    useEffect(() => {
        const hasAnyRights = rights.reduce((accumulator, item) => {
            accumulator = accumulator || userPermissions[item];
            return accumulator;
        }, false)
        setShowNoRightsMessage(!hasAnyRights);
    }, [userPermissions]);

    const handleTabChange = (key: string) => {
        setActiveKey(key);
        sessionStorage.setItem("tabKey", key);
    }

    return (
        <Container className="position-relative pb-5 chat-container">
            {showNoRightsMessage && <div>
                <Alert variant="warning" className="">
                    <PiWarningBold size={22}/><span className="ps-3">{t('permission.permViewAlert')}</span>
                </Alert>
            </div>}
            {!showNoRightsMessage &&
                <Tabs
                    id="controlled-tabs-list"
                    className="mt-4 px-5"
                    activeKey={activeKey}
                    onSelect={(k) => handleTabChange(k ?? '')}
                >
                    {userPermissions['assistant.view_assistant'] &&
                        <Tab eventKey="assistants" title={
                            <div className="d-inline-flex align-items-center justify-content-center">
                                <img className="me-1" src="images/assistant.png" alt={t('assistants.title')}
                                     width={22}/>
                                <span>{t('assistants.title')}</span>
                            </div>
                        } className="px-2">
                            {activeKey === 'assistants' && <Assistants/>}
                        </Tab>
                    }
                    {userPermissions['file.view_file'] &&
                        <Tab eventKey="file" title={
                            <div className="d-inline-flex align-items-center justify-content-center">
                                <img className="me-1" src="images/stat.png" alt={t('file.tab_title')} width={28}/>
                                <span>{t('file.tab_title')}</span>
                            </div>
                        } className="px-2">
                            {activeKey === 'file' && <FilePage/>}
                        </Tab>
                    }
                    {userPermissions['file.view_file'] &&
                        <Tab eventKey="group" title={
                            <div className="d-inline-flex align-items-center justify-content-center">
                                <img className="me-1" src="images/stat.png" alt={t('group.tab_title')} width={28}/>
                                <span>{t('group.tab_title')}</span>
                            </div>
                        } className="px-2">
                            {activeKey === 'group' && <GroupPage/>}
                        </Tab>
                    }
                    {userPermissions['assistant.view_assistants'] &&
                        <Tab eventKey="stat" title={
                            <div className="d-inline-flex align-items-center justify-content-center">
                                <img className="me-1" src="images/stat.png" alt={t('assistants.title')} width={28}/>
                                <span>{t('stat.title')}</span>
                            </div>
                        } className="px-2">
                            {activeKey === 'stat' && <StatPage/>}
                        </Tab>
                    }
                </Tabs>
            }
            <Toaster/>
        </Container>
    );
};

export default Home;